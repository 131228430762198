<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="font-weight-regular text-h2">
          <v-avatar
            color="white"
            height="64"
            width="64"
          >
            <img src="@/assets/toucan.svg">
          </v-avatar>
          <span class="logo-normal police-toucan pl-3">Toucan</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- <v-divider class="mb-1" /> -->

    <v-list
      dense
      nav
    >
      <base-item-group :item="profile" />
      <div
        v-if="this.$store.state.compteClient"
        class="ml-2"
      >
        {{ $store.state.compteClient.nom }}
      </div>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in monMenu">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import Salon from '@/services/salon'

  // Utilities
  import { mapState } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      estResponsableCompteClient: undefined,
      estResponsableKiosque: undefined,
      infoSalon: undefined,
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      monMenu () {
        const menu = [
          {
            icon: 'mdi-home-circle-outline',
            title: this.$t('menu.salle_accueil'),
            to: '/orka/salleaccueil',
          },
        ]
        if (this.$store.state.userInfo?.roles === 'admin') {
          menu.push({
            group: '/admin',
            icon: 'mdi-tools',
            title: this.$t('menu.administration'),
            children: [
              {
                title: this.$t('menu.usagers'),
                to: 'gestionUsagers',
              },
              {
                title: this.$t('menu.comptes_clients'),
                to: 'gestionComptesClients',
              },
              {
                title: this.$t('menu.saut_temporel'),
                to: 'hack',
              },
              {
                title: this.$t('menu.salons'),
                to: 'salons',
              },
              {
                title: this.$t('menu.presences'),
                to: 'presences',
              },
              {
                title: this.$t('menu.importation'),
                to: 'importation',
              },
              {
                title: this.$t('menu.configuration_rtc'),
                to: 'configuration-rtc',
              },
            ],
          })
        }
        if (this.estResponsableCompteClient) {
          menu.push(
            {
              group: '/gestion',
              icon: 'mdi-cog',
              title: this.$t('menu.gestion'),
              children: [
                {
                  title: this.$t('menu.gestion_des_evenements'),
                  to: 'gestionEvenements',
                },
                {
                  title: this.$t('menu.gestion_des_kiosques'),
                  to: 'gestionKiosques',
                },
              ],
            },
            {
              group: '/sondage',
              icon: 'mdi-chart-box-outline',
              title: this.$t('sondage.sondage'),
              children: [
                {
                  title: this.$t('menu.gestion_des_sondages'),
                  to: 'gestionSondages',
                },
              ],
            },
          )
        }
        if (this.$store.state.evenementActif) {
          menu.push({
            icon: 'mdi-google-classroom',
            title: this.$t('menu.salle_des_présentations'),
            to: `/orka/eve/${this.$store.state.evenementActif.id}/sallepresentations`,
          })
        }
        if (this.$store.state.evenementActif && this.$store.state.evenementActif?.infoConferencier) {
          menu.push({
            icon: 'mdi-account-tie',
            title: this.$t('conferencier.les_conférenciers'),
            to: `/orka/eve/${this.$store.state.evenementActif.id}/conferencier`,
          })
        }
        if (this.$store.state.evenementActif && this.$store.state.evenementActif?.infoProgramme) {
          menu.push({
            icon: 'mdi-newspaper-variant-outline',
            title: this.$t('evenement.programme'),
            to: `/orka/eve/${this.$store.state.evenementActif.id}/programme`,
          })
        }
        if (
        this.$store.state.evenementActif?.listeAffectationsKiosques?.length > 0
        ) {
          menu.push({
            icon: 'mdi-face-man-profile',
            title: this.$t('menu.salon_des_exposants'),
            to: `/orka/eve/${this.$store.state.evenementActif.id}/sallekio`,
          })
        }
        if (this.infoSalons?.mesSalons.length > 0) {
          menu.push(
            // {
            //   icon: 'mdi-account-group',
            //   title: 'Salle sociale',
            //   to: '/orka/sallesoc',
            // },
            {
              group: '/orka',
              icon: 'mdi-chat-outline',
              title: this.$t('menu.salle_privée'),
              children: this.menuSalons(),
            },
          )
        }
        if (this.$store.state.evenementActif) {
          menu.push({
            icon: 'mdi-help-circle-outline',
            title: this.$t('general.aide'),
            to: `/orka/eve/${this.$store.state.evenementActif.id}/aideevenement`,
          })
        }
        return menu
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        if (this.estResponsableCompteClient || this.estResponsableKiosque || this.$store.state.userInfo?.roles === 'admin') {
          return {
            avatar: true,
            group: '',
            title:
              this.$store.state.userInfo.prenom +
              ' ' +
              this.$store.state.userInfo.nom,
            children: [
              {
                href: '',
                title: this.$t('general.profil'),
                to: 'orka/profil',
              },
              {
                href: '',
                title: this.$t('sondage.mes_sondages'),
                to: 'orka/mesSondages',
              },
            ],
          }
        } else {
          return {
            avatar: true,
            group: '',
            title:
              this.$store.state.userInfo.prenom +
              ' ' +
              this.$store.state.userInfo.nom,
            children: [
              {
                href: '',
                title: this.$t('sondage.mes_sondages'),
                to: 'orka/mesSondages',
              },
            ],
          }
        }
      },
    },
    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },
    mounted () {
      this.estResponsableKiosque = this.$store.state.kiosque !== undefined
      this.estResponsableCompteClient = this.$store.state.compteClient !== undefined
      Salon.majInfoSalons()
      this.infoSalons = Salon.infoSalons
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      menuSalons () {
        const res = this.infoSalons.mesSalons.map(s => {
          return {
            title: s.nom,
            to: 'sallepri/' + s.identifiant,
          }
        })

        return res
      },
    },
  }
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
